import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import callApi from '../../services/api';
import withOrderContext from '../../withOrderContext';
import withCustomerContext from '../../withCustomerContext';
import { pushToLayer } from '../../services/googleTagManager';

import { colors, mobileThresholdPixels, Button as ButtonV3,
} from '../home/v3/styledComponents';
import { FormInput } from '../home/v2/styledComponents';
import Dots from '../home/v3/Dots';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16.5px;
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.column && 'flex-direction: column; margin-top: 70.5px;'}
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}px;`}
  font-family: Libre Baskerville;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  @media (max-width: ${mobileThresholdPixels}) {
    align-items: flex-start;
    ${props => props.column && 'margin-top: 30px; align-items: center;'}
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 30px;
  }
`;

const SubTitle = styled.div`
  font-family: Libre Baskerville;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 20px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }
`;

const Button = styled(ButtonV3)`
  padding: 3px 15px 0px;
  margin: 0px;
  font-size: 18px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 13px;
    width: unset;
    min-width: unset;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${mobileThresholdPixels}) {
    align-self: stretch;
  }
`;

const StyledFormInput = styled(FormInput)`
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  height: 38px;
  width: 300px;
  margin: 0px;
  padding: 0px 0px 0px 15px;
  border-radius: 2px;
  border-width: 0.5px;
  ::placeholder {
    color: ${colors.darkBlueGrey};
  }
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
    width: calc(100% - 15px);
  }
`;

const FieldLabel = styled.p`
  font-family: Roboto;
  font-size: 18px;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 20px;
  color: ${colors.navy};
  outline: none;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    margin-top: 0px;
  }
`;

const scrollToTop = () => {
  if (typeof window === 'undefined') return;
  window.scrollTo(0, 0);
};

class PasswordForm extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.submit = this.submit.bind(this);
  }

  submit() {
    const { customerContext: { customer = {}, setIsShadowAccount } } = this.props;
    const { _id: customerId } = customer;
    const { password } = this.state;
    return callApi(`public/setPassword/${customerId}`, 'post', { password })
      .then(() => {
        this.setState({ isPasswordSet: true }, () => scrollToTop());
        pushToLayer({
          event: 'Account Created',
          context: 'post_order',
        });
        setIsShadowAccount();
      });
  }

  render() {
    const { isPasswordSet } = this.state;
    return (
      <Container>
        <Header column marginBottom={31}>
          Gagnez du temps la prochaine fois
          <Dots marginTop={5} noMargin />
        </Header>

        <SubTitle>
          {isPasswordSet
            ? 'Votre compte Tilli est actif, vos coordonnées sont enregistrées pour votre prochaine commande !'
            : 'Créez vous un compte Tilli et enregistrez vos coordonnées pour votre prochaine commande.'
          }
        </SubTitle>

        {isPasswordSet
          ? null
          : (
            <Row>
              <Column>
                <FieldLabel>Mot de passe</FieldLabel>
                <StyledFormInput
                  name="password"
                  type="password"
                  onChange={e => this.setState({ password: e.target.value })}
                />
              </Column>

              <Button navy onClick={this.submit}>
                Sauvegarder mes infos
              </Button>
            </Row>)
        }
      </Container>
    );
  }
}

PasswordForm.propTypes = {
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({}),
  }).isRequired,
};

export default withCustomerContext(withOrderContext(PasswordForm));
