import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { Title2 } from './styledComponents';
import { mobileThresholdPixels, margins, Column, LineV, colors } from '../v2/styledComponents';

import maisonStandardsLogo from '../../../assets/partners/maisonStandardsParis.png';
import galeriesLafayetteLogo from '../../../assets/partners/galeriesLafayette.png';
import bhvLogo from '../../../assets/partners/Bhv.png';
import maduraLogo from '../../../assets/partners/madura.jpg';

const partnerImages = [
  { img: maisonStandardsLogo, alt: 'couturiers certifiés' },
  { img: galeriesLafayetteLogo, alt: 'meilleure retoucherie de Paris' },
  { img: bhvLogo, alt: 'couturière à Paris, Lyon, Bordeaux et Marseille' },
  { img: maduraLogo, alt: 'retouches rideaux à Paris, Lyon, Bordeaux et Marseille' },
];

const Container = styled.div`
  padding: 74px 0px 40px 0px;
  background: ${props => props.linen ? colors.linen : colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 35px 0px 30px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.partnersBlock && `
    width: 200px;
    height: 200px;
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    ${props => props.partnersBlock && `
      width: 25vw;
      height: unset;
    `}
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 0px ${margins.s};
  }
`;

const Img = styled.img`
  max-height: 100px;
  height: auto;
  min-width: 150px;
  max-width: 250px;
  object-fit: contain;

  @media (max-width: ${mobileThresholdPixels}) {
    height: unset;
    min-width: auto;
    max-width: 100%;
  }
`;

const StyledLineV = styled(LineV)`
  height: 100px;
  margin: 0px 125px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 50px;
    margin: 0px 15px;
  }
`;

const PartnerBlock = (index, brands) => {
  const definedBrand = brands?.find(brand => brand.index === index);
  return (
    <Row partnersBlock>
      <Link to="/ils-nous-font-confiance-les-partenaires-tilli">
        <Img
          src={definedBrand?.img.publicURL || partnerImages[index].img}
          alt={partnerImages[index].alt}
        />
      </Link>
    </Row>
  );
};

const PartnersPager = ({ curtainsVersion, linen, brands }) => (
  <Container lightGrey linen={linen}>
    <Column justifyContent={'center'} alignItems={'center'}>
      <Title2 marginBottom>
        {curtainsVersion
          ? 'Les spécialistes des rideaux nous font confiance'
          : 'Ils nous confient leurs retouches'
        }
      </Title2>
    </Column>
    <SubContainer>
      {curtainsVersion
        ? <Row>{PartnerBlock(3)}</Row>
        : <Row>
          {PartnerBlock(0, brands)} <StyledLineV />
          {PartnerBlock(1, brands)} <StyledLineV />
          {PartnerBlock(2, brands)}
        </Row>
      }
    </SubContainer>
  </Container>
);

PartnersPager.propTypes = {
  linen: PropTypes.bool,
  curtainsVersion: PropTypes.bool,
  brands: PropTypes.arrayOf(PropTypes.shape({
    img: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
    index: PropTypes.number,
  })),
};

PartnersPager.defaultProps = {
  linen: false,
  curtainsVersion: false,
  brands: undefined,
};

export default PartnersPager;
