import React from 'react';

import Layout from '../../layouts/index';
import { Container, Content, StepContainer } from '../../components/home/v3/styledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/v3/Footer';
import MasksSuccess from '../../components/order/masks/MasksSuccess';
import PartnersPager from '../../components/home/v3/PartnersPager';

const MasksSuccessPage = () => (
  <Layout routeSlug="MasksSuccess">
    <Container hasNoBanner>
      <MenuBar showGiftBanner={false} showBannerMask={false} />
      <Content>
        <StepContainer>
          <MasksSuccess />
        </StepContainer>
      </Content>
      <PartnersPager linen />
      <Footer />
    </Container>
  </Layout>
);

export default MasksSuccessPage;
